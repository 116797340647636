import Vue from 'vue'
import { without } from 'lodash'
import {
  getCustomerStockCard,
  getCustomerWineReserves,
  getCustomerWineAllocations,
  reserveCreateBuyBack,
  updateInventoryUsage
} from '../../api/reserves/customerStockCard'
import { WineUsage } from '../../constants/wineUsage'
import { getWineCardsByIds } from '~/api/wineBrowser/wines'

export const state = () => ({
  contact: {},
  wines: [],
  brokingWines: [],
  activeWineCardId: null,
  allocations: {},
  buckets: {
    buyBacks: {},
    deliveries: {}
  },
  allocated: 0,
  loading: false,
  wineCards: [],
  buttonDataLoading: false
})

export const getters = {
  getActiveAllocations: state => {
    return state.allocations[state.activeWineCardId] || []
  },
  getAllocationsForWineCardID: state => wineCardId => {
    return state.allocations[wineCardId] || []
  },
  getActiveWine: state => {
    for (let i = 0; i < state.wines.length; i++) {
      if (state.wines[i].WineCardID === state.activeWineCardId) {
        return state.wines[i]
      }
    }
    return null
  },
  getActiveWineForWineCardID: state => wineCardId => {
    for (let i = 0; i < state.wines.length; i++) {
      if (state.wines[i].WineCardID === wineCardId) {
        return state.wines[i]
      }
    }
    return null
  },
  getWineCardIds: (state, _) => {
    return state.wines.map(wine => wine.WineCardID)
  },
  getActiveWineCardForWineCardID: state => wineCardId => {
    for (let i = 0; i < state.wineCards.length; i++) {
      if (state.wineCards[i].id === wineCardId) {
        return state.wineCards[i]
      }
    }
    return null
  },
  getLines: state => bucket => {
    return state.buckets[bucket][state.contact.ContactID] || []
  }
}

export const mutations = {
  setContact(state, contact) {
    state.contact = contact
  },
  setWines(state, wines) {
    state.wines = wines
  },
  setBrokingWines(state, brokingWines) {
    state.brokingWines = brokingWines
  },
  setActiveWineCardId(state, wineCardId) {
    state.activeWineCardId = wineCardId
  },
  setAllocations(state, { wineCardId, allocations }) {
    Vue.set(state.allocations, wineCardId, allocations)
  },
  appendRequest(state, { bucket, ContactId, allocation }) {
    const contactAllocations = state.buckets[bucket][ContactId] || []
    contactAllocations.push(allocation)
    Vue.set(state.buckets[bucket], ContactId, contactAllocations)
  },
  resetRequest(state, { bucket, ContactId }) {
    Vue.set(state.buckets[bucket], ContactId, [])
  },
  amendRequest(state, { bucket, line, bottles }) {
    const lines = state.buckets[bucket][state.contact.ContactID]
    for (let i = 0; i < lines.length; i++) {
      if (line === lines[i]) {
        Vue.set(lines[i], 'Bottles', bottles)
        return Vue.set(state.buckets[bucket], state.contact.ContactID, lines)
      }
    }
  },
  setStock(state, { bucket, bottles, InventoryID, RotationNumber, line }) {
    const lines = state.buckets[bucket][state.contact.ContactID]
    for (let i = 0; i < lines.length; i++) {
      if (line === lines[i]) {
        const foundLine = lines[i]
        if (bottles > 0) {
          Vue.set(foundLine.Allocations, InventoryID, {
            Bottles: bottles,
            RotationNumber: RotationNumber
          })
        } else {
          Vue.delete(foundLine.Allocations, InventoryID)
        }
        lines[i] = foundLine
        return Vue.set(state.buckets[bucket], state.contact.ContactID, lines)
      }
    }
  },
  mergeStock(state, { bucket, line, mergeData }) {
    let lines = state.buckets[bucket][state.contact.ContactID]
    for (let i = 0; i < lines.length; i++) {
      if (line === lines[i]) {
        const foundLine = lines[i]
        lines[i] = Object.assign(foundLine, mergeData)
        const duplicateLines = lines.filter(l => {
          return (
            lines[i].WineCardID === l.WineCardID &&
            lines[i].DutyStatusID === l.DutyStatusID &&
            lines[i].WineUsageCodeID === l.WineUsageCodeID &&
            lines[i].Price === l.Price &&
            lines[i].Broking === l.Broking
          )
        })

        // Take first duplicate line merge the allocations and remove the rest
        if (duplicateLines.length > 1) {
          const newLine = duplicateLines[0]
          for (let ii = 1; ii < duplicateLines.length; ii++) {
            const dl = duplicateLines[ii]
            newLine.Bottles += dl.Bottles
            Object.keys(dl.Allocations).forEach(k => {
              if (!newLine.Allocations[k]) {
                newLine.Allocations[k] = dl.Allocations[k]
              } else {
                newLine.Allocations[k].Bottles += dl.Allocations[k].Bottles
              }
            })
          }
          lines = lines.filter(l => duplicateLines.indexOf(l) === -1)
          lines.push(newLine)
        }

        Vue.set(state.buckets[bucket], state.contact.ContactID, lines)
        i = lines.length
      }
    }
  },
  autoAllocate(state, type) {
    const lines = state.buckets[type][state.contact.ContactID]

    let allocated = 0

    for (let i = 0; i < lines.length; i++) {
      // Not Allocated
      if (Object.keys(lines[i].Allocations).length === 0) {
        const allocations = state.allocations[lines[i].WineCardID]

        const availableBottles = allocations.reduce(function(a, b) {
          return a + b.AvailableBottles
        }, 0)
        // console.log(lines[i].Bottles, availableBottles)

        // If we are allocated everything, easy
        if (lines[i].Bottles === availableBottles) {
          // console.log('Lines Match')
          for (let j = 0; j < allocations.length; j++) {
            this.commit('reserves/customerStockCard/setStock', {
              bucket: type,
              bottles: allocations[j].AvailableBottles,
              line: lines[i],
              InventoryID: allocations[j].InventoryID,
              RotationNumber: allocations[j].RotationNumber
            })
          }
          allocated++
          // If there is only one place to get allocation from, win
        } else if (allocations.length === 1) {
          // console.log('Found One')
          this.commit('reserves/customerStockCard/setStock', {
            bucket: type,
            bottles: lines[i].Bottles,
            line: lines[i],
            InventoryID: allocations[0].InventoryID,
            RotationNumber: allocations[0].RotationNumber
          })
          allocated++
        } else {
          // console.log('No Matches')
        }
      }
    }

    state.allocated = allocated
  },
  removeStock(state, { bucket, line }) {
    const lines = state.buckets[bucket][state.contact.ContactID]
    Vue.set(
      state.buckets[bucket],
      state.contact.ContactID,
      without(lines, line)
    )
  },
  resetStock(state, bucket) {
    Vue.delete(state.buckets[bucket], state.contact.ContactID)
  },
  setLoading(state, loading) {
    state.loading = loading === true
  },
  updateBrokingPrice(state, result) {
    // console.log('commit updateBrokingPrice ', result)
    if (state.brokingWines) {
      const index = state.brokingWines.findIndex(
        item => item.WineCardID === result.WineCardID
      )
      const wine = Object.assign({}, state.brokingWines[index], {
        BrokingPrice: result.Price
      })
      state.brokingWines.splice(index, 1, wine)
    }
  },
  setWineCards(state, wineCards) {
    state.wineCards = wineCards
  },
  setButtonDataLoading(state, buttonDataLoading) {
    state.buttonDataLoading = buttonDataLoading === true
  }
}

export const actions = {
  async getContact({ commit, dispatch }, id) {
    const result = await getCustomerStockCard(id)
    if (result) {
      commit('setContact', result)
      dispatch('reserves/reserves/appendContact', result, {
        root: true
      })
    }
    return result
  },
  async getWineStock({ commit }, id) {
    const result = await getCustomerWineReserves({
      ContactID: id
    })
    if (result) {
      commit('setWines', result)
    }
    return result
  },
  async getBrokingWineStock({ commit }, id) {
    const result = await getCustomerWineReserves({
      ContactID: id,
      WineUsageCodeID: WineUsage.BRO
    })
    if (result) {
      commit('setBrokingWines', result)
    }
    return result
  },
  async getWineAllocations({ commit, state }, id) {
    const allocations = await getCustomerWineAllocations({
      WineCardID: id,
      ContactID: state.contact.ContactID
    })
    if (allocations) {
      commit('setActiveWineCardId', id)
      commit('setAllocations', { wineCardId: id, allocations })
    }
    return allocations
  },
  async buyBackRequest({ commit }, { payload, bucket }) {
    const result = await reserveCreateBuyBack(payload)
    commit('resetStock', bucket)
    return result
  },
  async appendRequest(
    { commit, state, dispatch },
    { bucket, ContactId, allocation }
  ) {
    let alloc
    if (allocation.All) {
      commit('setLoading', true)

      await commit('setActiveWineCardId', null)
      await commit('resetRequest', {
        bucket: bucket,
        ContactId: ContactId
      })

      for (let i = 0; i < state.wines.length; i++) {
        await dispatch('getWineAllocations', state.wines[i].WineCardID)
        commit('setActiveWineCardId', null)
        alloc = {
          WineCardID: state.wines[i].WineCardID,
          WineVintage: state.wines[i].WineVintage,
          ComputedWineName: state.wines[i].ComputedWineName,
          FormatName: state.wines[i].FormatName,
          WineSellingPrice: state.wines[i].WineSellingPrice,
          FormatCaseSize: state.wines[i].FormatCaseSize,
          Bottles: state.wines[i].AvailableBottles,
          Price: state.wines[i].WineSellingPrice,
          DutyStatusID: allocation.DutyStatusID,
          WineUsageCodeID: allocation.WineUsageCodeID,
          Allocations: {}
        }
        commit('appendRequest', {
          bucket: bucket,
          ContactId: ContactId,
          allocation: alloc
        })
      }

      await commit('autoAllocate', 'deliveries')

      commit('setLoading', false)
    } else {
      commit('appendRequest', {
        bucket: bucket,
        ContactId: ContactId,
        allocation: allocation
      })
    }
  },
  async updateInventoryUsage({ dispatch }, payload) {
    const result = await updateInventoryUsage(payload)
    dispatch('getWineAllocations', payload.WineCardID)
    return result
  },
  async getBrowserCards({ commit, getters }) {
    if (getters.getWineCardIds.length > 0) {
      commit('setButtonDataLoading', true)
      try {
        const result = await getWineCardsByIds(getters.getWineCardIds)
        await commit('setWineCards', result)
        return true
      } catch (e) {
        throw e
      } finally {
        commit('setButtonDataLoading', false)
      }
    }
  }
}
