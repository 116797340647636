import Entity from './entity'
import { auditFields } from './helpers'
import { fields as wineBrowserFields } from './winebrowsersearchresult'

export const fields = {
  WineCardID: {
    type: Number
  },
  WineNameID: {
    type: Number
  },
  WineName: {
    type: String
  },
  ProducerID: {
    type: Number
  },
  WineVintage: {
    type: Number
  },
  FormatID: {
    type: Number
  },
  FormatName: {
    type: String
  },
  CategoryID: {
    type: Number
  },
  CategoryName: {
    type: String
  },
  AppellationName: {
    type: String
  },
  CountryID: {
    type: Number
  },
  CountryName: {
    type: String
  },
  WineSellingPrice: {
    type: Number
  },
  WineBookPrice: {
    type: Number
  },
  WineExtract: {
    type: String
  },
  WineFDA: {
    type: String
  },
  WinePercentOH: {
    type: Number
  },
  WinePH: {
    type: String
  },
  WineSO2: {
    type: String
  },
  WineParkerScore: {
    type: String
  },
  WineParkerTastingNotes: {
    type: String
  },
  WineBurghoundScore: {
    type: String
  },
  WineBurghoundTastingNotes: {
    type: String
  },
  WineDescription: {
    type: String
  },
  WinePriceWeightedAverage: {
    type: Number
  },
  MaxStockPrice: {
    type: Number
  },
  WineUsageCodeID: {
    type: Number
  },
  UsageCode: {
    type: String
  },
  WineColourID: {
    type: Number
  },
  WineColour: {
    type: String
  },
  PurchaseOrderLinesUsage: {
    type: Number
  },
  SalesOrderLinesUsage: {
    type: Number
  },
  LinkedPurchaseOrderLinesUsage: {
    type: Number
  },
  LinkedSalesOrderLinesUsage: {
    type: Number
  },
  ...auditFields,
  AuditAmendedByUserName: {
    type: String
  },
  PromotionDate: {
    type: String
  },
  DoNotPromote: {
    type: Boolean
  },
  OverrideLossCheck: {
    type: Boolean
  },
  DutyTypeID: {
    type: Number
  },
  DutyTypeName: {
    type: String
  },
  LivExLWinCode: {
    type: Number
  },
  LivExLWinExempt: {
    type: Boolean
  },
  MixedCase: {
    type: Boolean
  },
  PhotoRequired: {
    type: Boolean
  },
  ProducerName: {
    type: String
  },
  EmptyCaseInfo: {
    type: String
  },
  DesignationID: {
    type: Number
  },
  ClassificationID: {
    type: Number
  },
  CountryRegionID: {
    type: Number
  },
  CountrySubRegionID: {
    type: Number
  },
  Exception: {
    type: String
  },
  VineyardID: {
    type: Number
  },
  LinkedCardCount: {
    type: Number
  },
  AbvVerified: {
    type: Number
  },
  AbvVerifiedByName: {
    type: String
  },
  MixedCaseDefaultSize: {
    type: Number
  },
  ComputedWineName: {
    type: String
  },
  LooseBottlePrice: {
    type: Number
  },
  DutyPer9L: {
    type: Number
  }
}

const WineCard = new Entity('WineCard', fields)

WineCard.createQuery('get', 'wineCardGet', fields)
WineCard.createQuery('getInitialVintages', 'wineCardInitialVintages', {})
WineCard.createQuery(
  'getByLwin',
  'wineCardGetByLwin',
  Entity.allButKeys(fields, [
    'LinkedPurchaseOrderLinesUsage',
    'LinkedSalesOrderLinesUsage',
    'PurchaseOrderLinesUsage',
    'SalesOrderLinesUsage',
    'WinePriceWeightedAverage'
  ]),
  {
    LWINs: {
      type: Array
    }
  }
)

WineCard.createMutation(
  'create',
  'wineCardAdd',
  Entity.onlyKeys(fields, [
    'WineNameID',
    'ProducerID',
    'WineVintage',
    'FormatID',
    'WineSellingPrice',
    'CategoryID',
    'CountryID',
    'WineColourID',
    'WineParkerScore',
    'WineUsageCodeID',
    'WineExtract',
    'WineFDA',
    'WinePercentOH',
    'WinePH',
    'WineSO2',
    'WineDescription',
    'DoNotPromote',
    'OverrideLossCheck',
    'MixedCase',
    'LivExLWinCode',
    'LivExLWinExempt',
    'WineParkerScore',
    'WineParkerTastingNotes',
    'WineBurghoundScore',
    'WineBurghoundTastingNotes',
    'CountryRegionID',
    'CountrySubRegionID',
    'DesignationID',
    'ClassificationID',
    'Exception',
    'VineyardID',
    'AbvVerified',
    'MixedCaseDefaultSize',
    'LooseBottlePrice'
  ])
)

WineCard.createMutation(
  'update',
  'wineCardUpdate',
  Entity.onlyKeys(fields, [
    'WineCardID',
    'WineSellingPrice',
    'WineParkerScore',
    'AuditAmendedDate',
    'WineNameID',
    'ProducerID',
    'WineVintage',
    'FormatID',
    'CategoryID',
    'CountryID',
    'WinePercentOH',
    'WinePH',
    'WineExtract',
    'WineSO2',
    'WineFDA',
    'WineParkerTastingNotes',
    'WineBurghoundScore',
    'WineBurghoundTastingNotes',
    'WineDescription',
    'WineBookPrice',
    'WineUsageCodeID',
    'WineColourID',
    'PromotionDate',
    'DoNotPromote',
    'OverrideLossCheck',
    'LivExLWinCode',
    'LivExLWinExempt',
    'MixedCase',
    'ReservedBottles',
    'CountryRegionID',
    'CountrySubRegionID',
    'DesignationID',
    'ClassificationID',
    'Exception',
    'VineyardID',
    'AbvVerified',
    'MixedCaseDefaultSize',
    'LooseBottlePrice'
  ])
)
WineCard.createMutation(
  'delete',
  'wineCardDelete',
  {
    ID: fields.WineCardID
  },
  []
)

WineCard.createMutation(
  'updateBatchPromotion',
  'wineCardSetBatchPromotionDate',
  {
    WineCards: {
      type: Array,
      fields: {
        id: {
          type: Number
        },
        date: {
          type: String
        }
      }
    }
  },
  wineBrowserFields
)

export default WineCard
