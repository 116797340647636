import Entity from './entity'
import SalesOrder from './salesorder'
import { fields as webSalesOrderFields } from './websalesorderline'

const fields = {
  SOLID: {
    type: Number
  },
  SOID: {
    type: Number
  },
  WineCardID: {
    type: Number
  },
  TransactionOrder: {
    type: Number
  },
  WineVintage: {
    type: Number
  },
  ComputedWineName: {
    type: String
  },
  FormatID: {
    type: Number
  },
  FormatName: {
    type: String
  },
  CaseSize: {
    type: Number
  },
  StockTotalBottles: {
    type: Number
  },
  SellingPrice: {
    type: Number
  },
  TransactionTotalBottles: {
    type: Number
  },
  Price: {
    type: Number
  },
  Value: {
    type: Number
  },
  DutyStatusIDBought: {
    type: Number
  },
  DutyStatusIDSold: {
    type: Number
  },
  IsVAT: {
    type: Number
  },
  WineUsageCodeID: {
    type: Number
  },
  WineUsageCode: {
    type: String
  },
  AllocatedTotalBottles: {
    type: Number
  },
  UnallocatedTotalBottles: {
    type: Number
  },
  SalesAllocationUsage: {
    type: Number
  },
  SalesAlloc: {
    type: Number
  },
  OversellCount: {
    type: Number
  },
  RotationNumber: {
    type: String
  },
  SOOCount: {
    type: Number
  },
  UserInitials: {
    type: String
  },
  FormatCode: {
    type: String
  },
  FormatVolume: {
    type: Number
  },
  DutyPerCase: {
    type: Number
  },
  ExportID: {
    type: Number
  },
  CategoryID: {
    type: Number
  },
  AuditCreatedDate: {
    type: String
  },
  AuditCreatedBy: {
    type: Number
  },
  AuditAmendedDate: {
    type: String
  },
  AuditAmendedBy: {
    type: Number
  },
  WineCardAuditAmendedDate: {
    type: String
  },
  MaxStockPrice: {
    type: Number
  },
  LivExLWinCode: {
    type: Number
  },
  WebSalesOrderLine: {
    type: Object,
    fields: webSalesOrderFields
  },
  SIID: {
    type: Number
  },
  ReservePrice: {
    type: Number
  },
  Abv: {
    type: Number
  }
}

const SalesOrderTransaction = new Entity('SalesOrderTransaction', fields)

SalesOrderTransaction.createQuery('get', 'salesOrderTransactionsGet', fields)

SalesOrderTransaction.createMutation(
  'update',
  'salesOrderLineUpdate',
  {
    ...Entity.onlyKeys(fields, [
      'SOLID',
      'WineCardID',
      'FormatID',
      'TransactionOrder',
      'Price',
      'ReservePrice',
      'DutyStatusIDBought',
      'DutyStatusIDSold',
      'WineUsageCodeID',
      'AuditAmendedDate'
    ]),
    Bottles: {
      type: Number
    }
  },
  {
    ...Entity.onlyKeys(fields, [
      'SOLID',
      'WineCardID',
      'FormatID',
      'TransactionOrder',
      'Price',
      'ReservePrice',
      'DutyStatusIDBought',
      'DutyStatusIDSold',
      'WineUsageCodeID',
      'AuditAmendedDate'
    ]),
    Transaction: {
      type: Object,
      fields
    },
    SalesOrder: {
      type: Object,
      fields: SalesOrder.fields
    }
  }
)

SalesOrderTransaction.createMutation(
  'delete',
  'salesOrderLineDelete',
  Entity.onlyKeys(fields, ['SOLID']),
  []
)

SalesOrderTransaction.createMutation(
  'create',
  'salesOrderLineAdd',
  {
    ...Entity.onlyKeys(fields, ['SOID', 'WineCardID', 'FormatID', 'Price']),
    Bottles: {
      type: Number
    },
    AsDutyPaid: {
      type: Number
    }
  },
  {
    ...Entity.onlyKeys(fields, [
      'SOLID',
      'SOID',
      'WineCardID',
      'FormatID',
      'Bottles',
      'Price',
      'DutyStatusIDBought',
      'DutyStatusIDSold',
      'IsVAT',
      'WineUsageCodeID',
      'WebOrderLineID',
      'AuditCreatedBy',
      'AuditAmendedDate'
    ]),
    Transaction: {
      type: Object,
      fields
    },
    SalesOrder: {
      type: Object,
      fields: SalesOrder.fields
    }
  }
)

export default SalesOrderTransaction
